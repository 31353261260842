import React, { useContext } from 'react'
import styled from 'styled-components'
import { useRedirect } from '../../hooks/useRedirect'
import { useNoValidate } from '../../hooks/useNoValidate'
import { EventContext } from '../../components/context/EventContext'
import { colors } from '../../theme'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import {
  NumberInput,
  Button,
  Dropdown,
  StyledDropdown,
  StyledTextInput,
  Pulse,
} from '@monbanquet/crumble'
import { navigate } from '../../components/Link'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import { useWithDelay } from '../../hooks/useWithDelay'
import { defaultBudgetTypes } from '../../components/context/EventContextDefaults'
import DemandeLayout from '../../components/DemandeLayout'
import { computeNextPageWithBaseUri } from '../../util/compute-next-page'

const schema = yup.object().shape({
  budget: yup
    .number()
    .nullable()
    .positive('Vérifiez le budget')
    .required('Indiquez un budget'),
})

const SpecificBudgetPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const {
    state: { budget, budgetType },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const noValidate = useNoValidate()

  const initialValues = {
    budget: budget.specific,
  }

  const submit = () => {
    navigate(computeNextPageWithBaseUri('demande', 'budget-specifique'), {
      state: { next: true },
    })
  }

  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  const { formal, budgetProps } = useFormalWithProps(initialValues, {
    schema,
    onSubmit: submitWithDelay,
  })

  return (
    <StyledSpecificBudgetPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle title={`Quel est votre budget cible\u00A0?`} />
            <form
              noValidate={noValidate}
              onSubmit={async e => {
                e.preventDefault()
                try {
                  await formal.validate()
                  await formal.submit()
                } catch (err) {
                  console.info(err || formal.errors)
                }
              }}
            >
              <div className="dropdown-input">
                <NumberInput
                  autoComplete="off"
                  secondary
                  min="0"
                  required
                  placeholder={
                    budgetType === 'perPers' ? 'ex: 12€' : 'ex: 700€'
                  }
                  {...budgetProps}
                  onChange={b => {
                    formal.change('budget', b)
                    dispatchEventCtx({
                      type: 'UPDATE',
                      payload: {
                        budget: { min: null, max: null, specific: b },
                      },
                    })
                  }}
                />
                <Dropdown
                  secondary
                  items={defaultBudgetTypes}
                  value={budgetType}
                  onChange={bT =>
                    dispatchEventCtx({
                      type: 'UPDATE',
                      payload: { budgetType: bT },
                    })
                  }
                />
              </div>
              <div className="btn-bar">
                <Button id="previous" onClick={() => navigate(-1)}>
                  Précédent
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledSpecificBudgetPage>
  )
}

const StyledSpecificBudgetPage = styled.div`
  .page-content {
    max-width: 500px;
  }

  p.subTitle {
    color: ${colors.text.light2};
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  form {
    margin-top: 40px;
    & > * {
      margin-bottom: 20px;
    }
    > .error {
      margin-top: 10px;
      color: ${colors.brand.dark2};
    }
  }

  .dropdown-input {
    display: flex;

    ${StyledTextInput} {
      flex-grow: 1;
    }

    ${StyledDropdown} {
      flex-basis: 40%;
      flex-shrink: 0;

      button {
        border: none;
        height: 66px;
        border-radius: 37px;
        margin-left: 10px;
        background-color: white;
        padding: 20px 45px;
      }

      ul {
        left: 10px;
      }
    }
  }
`

export default SpecificBudgetPage
export { StyledSpecificBudgetPage }
